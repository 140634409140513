<template>
    <!--新增对话框-->
    <el-dialog title="详情" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        抬头资质:
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.invoiceType == 1">普票抬头</span>
                        <span v-if="detail.invoiceType == 2">专票抬头</span>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        抬头类型:
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.headingType == 1">企业单位</span>
                        <span v-if="detail.headingType == 2">个人/非企业单位</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        发票抬头
                    </el-col>
                    <el-col :span="18">
                        {{ detail.invoiceHeader }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        纳税人识别号
                    </el-col>
                    <el-col :span="18">
                        {{ detail.dutyParagraph }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        银行账号：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.bankAccount }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        开户银行
                    </el-col>
                    <el-col :span="18">
                        {{ detail.depositBank }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        注册地址
                    </el-col>
                    <el-col :span="18">
                        {{ detail.companyRegisteredAddress }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        注册电话
                    </el-col>
                    <el-col :span="18">
                        {{ detail.registrationPhone }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        企业邮箱
                    </el-col>
                    <el-col :span="18">
                        {{ detail.enterpriseMailbox }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        申请状态
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.invoiceStatus == 1">申请中</span>
                        <span v-if="detail.invoiceStatus == 2">已开票</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        货物
                    </el-col>
                    <el-col :span="18">
                        {{ detail.goods }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        开票金额
                    </el-col>
                    <el-col :span="18">
                        {{ detail.invoiceTotal }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        收件人姓名
                    </el-col>
                    <el-col :span="18">
                        {{ detail.recipientInformation }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        收件人手机号
                    </el-col>
                    <el-col :span="18">
                        {{ detail.phone }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        收件人地址
                    </el-col>
                    <el-col :span="18">
                        {{ detail.recipientAddress }}
                    </el-col>
                </el-row>
            </el-col>

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        收件人邮箱
                    </el-col>
                    <el-col :span="18">
                        {{ detail.emailAddress }}
                        <!-- <span v-if="detail.orderGrabbingTime">  {{ format(new Date(detail.orderGrabbingTime), 'yyyy-MM-dd hh:mm:ss') }}</span> -->
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        运输起始点
                    </el-col>
                    <el-col :span="18">
                        {{ detail.startingPoint }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        运输终止点
                    </el-col>
                    <el-col :span="18">
                        {{ detail.endPoint }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        申请时间
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.createdTime"> {{ format(new Date(detail.createdTime), 'yyyy-MM-dd hh:mm:ss')
                        }}</span>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        开票时间
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.updateTime&&detail.invoiceStatus == 2"> {{ format(new Date(detail.updateTime), 'yyyy-MM-dd hh:mm:ss')
                        }}</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            imgVisible: false,
            imageUrl: null,
            detail: {},
            dialogVisible: false,
        }
    },
    methods: {
        handleClose(){
            this.dialogVisible=false
        },
        format(date, fmt) {
            var o = {
                "M+": date.getMonth() + 1,                 //月份 
                "d+": date.getDate(),                    //日 
                "h+": date.getHours(),                   //小时 
                "m+": date.getMinutes(),                 //分 
                "s+": date.getSeconds(),                 //秒 
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
                "S": date.getMilliseconds()             //毫秒 
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            }
            for (var k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                }
            }
            return fmt;
        },

        init(invoiceId) {
            this.dialogVisible = true;
            this.$axios.post('/admin/invoice/getInvoiceInfo', { invoiceId: invoiceId }).then(res => {
                this.detail = JSON.parse(JSON.stringify(res.data))
            })
        },
    }
}
</script>

<style scoped>
.el-col {
    margin-bottom: 10px;
}
</style>
